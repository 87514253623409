.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #2748fd;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.right {
  text-align: right;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.rowC{display:flex; flex-direction:row; justify-content: space-around}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  margin: 0 auto;
  top: 51px;
  min-width: 550px;
  max-width: 800px;
  text-align: left;
  border: 1px solid #aaa;
  background-color: #3A3F44;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
  color: #fff
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(0, 0, 0, 0.5);
}

.output {
  font: 1rem 'Fira Sans', sans-serif;
}

label {
  display: inline-block;
  text-align: right;
}


input[type='text'],
input[type='password'] {
  width: 50%;
}
.form-group {
  width: 50%;
}
.form-group input[type='text']{
  width: 100%;
}
input {
  margin: .4rem 0;
}

@media (min-width: 1px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1000000px;
  }
}

.react-autosuggest{
  width: 100%;
}

.container .mainBox {
  min-width: 300px;
  max-width: 900px;
  flex-basis: auto;
  flex-grow: 1;
}
.container .box {
  max-width: 300px;
  flex-basis: auto;
  flex-grow: 1;
}

.customHover{
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.customHover:hover {
  color: #d9d9d9;
  text-decoration: underline;
  cursor: pointer;
}

.centerTable {
  margin: 0 auto; /* or margin: 0 auto 0 auto */
}

table{table-layout:fixed;}
td{width:1px;white-space:nowrap;}

.rotateMinus45{
  display: inline-block;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.grid-results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-gap: 2rem;
}

form p { display: table-row;}
form p label { display: table-cell;}
form p input { display: table-cell;}