body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.navbar-default .navbar-nav .open .dropdown-menu>li>a, .navbar-default .navbar-nav .open .dropdown-menu {
  background-color: #ffffff;
  color:#777;
  background-image: linear-gradient(to bottom,#ffffff 0,#ffffff 100%);
}

.navbar-default .dropdown-menu {
  background-color: #ffffff;
  color:#777;
  background-image: linear-gradient(to bottom,#ffffff 0,#ffffff 100%);
}

.navbar .navbar-nav .open .dropdown-menu>.active>a, .navbar .navbar-nav .open .dropdown-menu>.active>a:focus, .navbar .navbar-nav .open .dropdown-menu>.active>a:hover{
  background-color: #ffffff;
  color:#777;
  background-image: linear-gradient(to bottom,#ffffff 0,#ffffff 100%);
}